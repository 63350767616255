import React, { useState, useEffect } from "react";
import "./Header.scss";
import { ImagePass } from "../../Image/ImagePass";
import GatsbyLink from "../../GatsbyLink";
import { Carousel } from "../../Carousel";

import placeholderImage from "../../../images/placeholder.jpg";

export const Header = ({ eyebrow, heading, content, action, sliderImages }) => {
  const [isHomePage, setHomePage] = useState(false);
  useEffect(() => {
    setHomePage(false);
    if (location.pathname === "/") {
      setHomePage(true);
    }
  }, []);

  const swiperSettings = {
    id: "header-carousel",
    slidesPerView: 1,
    spaceBetween: 0,
    grabCursor: false,
    pagination: false,
    navigation: false,
    loop: true,
    autoplay: {
      delay: 5000,
    },
  };

  return (
    <section
      className={`page-header layout-wide ${
        isHomePage ? "page-header--home" : ""
      }`}
    >
      <div className="page-header__wrapper">
        <div className="page-header__container">
          {eyebrow && <span className="eyebrow">{eyebrow}</span>}
          <h1
            className="header"
            dangerouslySetInnerHTML={{ __html: heading }}
          />
          {content && <p className="content">{content}</p>}
          {action && (
            <>
              <GatsbyLink to={action.url} className="button-filled">
                <span className="button__text">{action.title}</span>
              </GatsbyLink>
            </>
          )}
        </div>
        <div className="page-header__carousel">
          {sliderImages && sliderImages.length > 1 ? (
            <Carousel swiperSettings={swiperSettings}>
              {Object.keys(sliderImages).length > 0 &&
                sliderImages?.map((item, index) => {
                  return (
                    <div className="slide" key={index}>
                      <ImagePass src={item.background} className="main-image" />
                    </div>
                  );
                })}
            </Carousel>
          ) : (
            <ImagePass
              src={sliderImages[0].background}
              className="main-image"
            />
          )}
        </div>
      </div>
    </section>
  );
};

Header.defaultProps = {
  sliderImages: [
    {
      background: {
        source_url: placeholderImage,
      },
    },
  ],
};
